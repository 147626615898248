<template>
  <div
    class="page search"
    v-if="services.length || users.length || groups.length || companys.length"
  >
    <div class="services" v-if="services.length">
      <div class="category">
        <label class="title">
          <span></span>
          <i
            class="el-icon--homeMore"
            v-if="total.service > 8"
            @click="jumpPage({ name: 'list', params: { key, categoryId } })"
          ></i>
        </label>
      </div>
      <div class="items">
        <serviceItem
          v-for="(service, index) in services"
          :key="index"
          :data="formatServiceItem(service)"
          :light-height="true"
          :keyword="key"
        />
      </div>
    </div>
    <template v-if="users.length">
      <label class="title">
        <i class="type el-icon-user-solid"></i>
        <i
          class="more el-icon--homeMore"
          v-if="total.user > 6"
          @click="
            jumpPage({ name: 'users', params: { key, categoryId, type: 3 } })
          "
        ></i>
      </label>
      <div class="box users">
        <div class="user-list">
          <div class="item" v-for="(user, index) in users" :key="index">
            <Avatar
              :src="formartImageUrl(user.profile_photo)"
              :to="{ name: 'personalCenter', params: { id: user.provider_id } }"
            />
            <!-- <img
              class="avatar"
              v-if="user.profile_photo"
              :src="formartImageUrl(user.profile_photo)"
              @click="jumpPage({name:'user', params:{id:user.provider_id}})"
            />
            <i
              v-else
              class="avatar el-icon-user-solid"
              @click="jumpPage({name:'user', params:{id:user.provider_id}})"
            ></i>-->
            <p class="info">
              <span
                class="name"
                v-if="user.name"
                v-html="highlighted(user.name.slice(0, 20), key)"
                @click="
                  routeJump({ name: 'personalCenter', params: { id: user.provider_id } })
                "
              ></span>
              <img
                v-if="user.country && user.country !== '0'"
                class="country"
                :src="getCountryIcon(user.country)"
              />
            </p>
            <ul class="tags">
              <template v-for="(tag, index1) in user.tags">
                <li :key="index1" v-html="highlighted(tag, key)"></li>
              </template>
            </ul>
            <actions :action-data="user.actions" :show-chat="true" />
          </div>
        </div>
      </div>
    </template>
    <template v-if="groups.length">
      <label class="title">
        <i class="type el-icon--group"></i>
        <i
          class="more el-icon--homeMore"
          v-if="total.group > 6"
          @click="
            jumpPage({ name: 'list-ges', params: { key, categoryId, type: 4 } })
          "
        ></i>
      </label>
      <div class="box">
        <div class="item-list">
          <template v-for="(item, index) in groups">
            <gigItem
              :key="index"
              :item="item"
              :small="true"
              type="4"
              :light-height="true"
              :keyword="key"
            />
          </template>
        </div>
      </div>
    </template>
    <template v-if="companys.length">
      <label class="title">
        <i class="type el-icon--company"></i>
        <i
          class="more el-icon--homeMore"
          v-if="total.company > 6"
          @click="
            jumpPage({ name: 'list-ges', params: { key, categoryId, type: 5 } })
          "
        ></i>
      </label>
      <div class="box">
        <div class="item-list">
          <template v-for="(item, index) in companys">
            <gigItem
              :key="index"
              :item="item"
              :small="true"
              type="5"
              :light-height="true"
              :keyword="key"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
  <noData v-else />
</template>
<script>
import serviceItem from "@/components/business/web-site/service-temp";
import noData from "@/components/business/web-site/not-data";
import actions from "@/components/business/web-site/action";
import gigItem from "@/components/business/web-site/gig_item";
import Avatar from "@/components/common/avatar";
export default {
  name: "Search",
  data() {
    return {
      lang: "",
      key: "",
      country: "",
      cateId: "",
      services: [],
      users: [],
      groups: [],
      companys: [],
      total: {},
      categoryId: 0
    };
  },
  components: { serviceItem, noData, actions, gigItem, Avatar },
  created() {
    this.key = this.$store.state.webStore.searchKey || "";
    this.categoryId = this.$route.params.categoryId || 0;
    this.getSearchData();
  },
  computed: {
    watchLocation() {
      return this.$store.state.webStore.location;
    },
    watchCategorysId() {
      return this.$store.state.webStore.categoryId;
    },
    watchSearchKey() {
      return this.$store.state.webStore.searchKey;
    },
    watchRoute() {
      return this.$route;
    }
  },
  watch: {
    watchRoute: {
      handler(val) {
        if (val.params.t) {
          // this.getSearchData();
          // location.reload();
        }
      },
      dreep: true
    },
    watchCategorysId(val) {
      // console.log(val, "/watchCategorysId");
      let key = this.$store.state.webStore.searchKey;
      this.$route.params.key = key;
      this.key = key;
      this.categoryId = val;
      this.getSearchData();
    },

    watchLocation: function(val) {
      // 带国家id查询接口返回数据无分组

      this.country = val[1] || val[0]; //parseInt();
      this.getSearchData();
    },
    watchSearchKey: {
      handler(val) {
        this.key = val;
        this.getSearchData();
      },
      dreep: true
    }
  },
  methods: {
    async getSearchData() {
      let params = {
        language: localStorage.getItem("langCode") || 1,
        userId: this.$store.getters.userInfo.id || "",
        country: this.$store.state.webStore.location[1] || this.country,
        categoryId: this.categoryId || this.$store.state.webStore.categoryId,
        searchKey: this.key || this.$store.state.webStore.searchKey,
        serviceJobSize: 8,
        providerSize: 6,
        groupSize: 6,
        enterpriseSize: 6
      };
      let result = await this.$http({
        url: "rest/home/v3/searchHomeData",
        params,
        withCredentials: false
      }).catch();
      if (result && result.data) {
        let item = result.data;

        this.services = item.serviceJobData.data;
        this.users =
          item.providerData && item.providerData.data
            ? this.formartUser(item.providerData.data)
            : [];
        this.groups =
          item.groupData && item.groupData.data
            ? this.formartGroups(item.groupData.data)
            : [];
        this.companys =
          item.enterpriseData && item.enterpriseData.data
            ? this.formartEnters(item.enterpriseData.data)
            : [];
        this.total = {
          service: item.serviceJobTotal,
          user: item.providerTotal,
          group: item.groupTotal,
          company: item.enterpriseTotal
        };
        // console.log(this.total, "/total");
      }
    },
    formartEnters(arr) {
      let newArr = [];
      arr.forEach(element => {
        let item = {
          avatar: element.cover,
          name: element.subject,
          id: element.pkId,
          desc: element.content,
          owner: element.providerId,
          tags:
            element.tag && element.tag.indexOf(",")
              ? element.tag.split(",")
              : element.tag !== ""
              ? [element.tag]
              : [],
          actions: {
            id: element.pkId,
            zanNum: element.praiseCount,
            replyCount: element.replyCount,
            type: 5,
            shareData: {
              title: element.subject,
              thumb: element.cover,
              desc: element.content,
              url: "/detail/" + element.pkId + "/5"
            }
          }
        };
        newArr.push(item);
      });
      return newArr;
    },
    formartGroups(arr) {
      let newArr = [];
      arr.forEach(element => {
        let item = {
          avatar: element.cover,
          name: element.subject,
          id: element.pkId,
          desc: element.content,
          owner: element.providerId,
          tags:
            element.tag && element.tag.indexOf(",")
              ? element.tag.split(",")
              : element.tag !== ""
              ? [element.tag]
              : [],
          actions: {
            id: element.pkId,
            zanNum: element.praiseCount,
            replyCount: element.replyCount,
            type: 4,
            shareData: {
              title: element.subject,
              thumb: element.cover,
              desc: element.content,
              url: "/detail/" + element.pkId + "/4"
            }
          }
        };
        newArr.push(item);
      });
      return newArr;
    },
    formartUser(arr) {
      let newArr = [];
      arr.forEach(element => {
        let item = {
          profile_photo: element.userAvatar,
          name: element.userName,
          country: element.country,
          tags:
            element.tag && element.tag.indexOf(",") !== -1
              ? element.tag.split(",")
              : element.tag !== ""
              ? [element.tag]
              : [],
          provider_id: element.providerId,
          uc_id: element.ucId,
          actions: {
            replyCount: element.replyCount,
            zanNum: element.praiseCount,
            id: element.providerId,
            type: 3,
            likeStatus: element.likeStatus,
            shareData: {
              title: element.userName,
              thumb: element.userAvatar,
              desc: element.aboutMe.slice(0, 30),
              url: "/user/pai_" + this.$Base64.encode(element.providerId)
            },
            userinfo: {
              id: element.providerId,
              name: element.userName,
              thumb: element.userAvatar
            }
          }
        };
        newArr.push(item);
      });
      return newArr;
    }
  }
};
</script>
<style lang="stylus" scoped>
label {
  &.title {
    padding: 0.9rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;

    i {
      font-size: 1.9rem;

      &.el-icon--homeMore {
        color: #c5c5c5;
        cursor: pointer;
      }
    }
  }
}

.search {
  .services {
    .items {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;

      .service-item {
        width: 49.75%;
        margin: 0 0.5% 0.5% 0;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  .title {
    padding: 0.9rem 0;
  }

  .box {
    .item-list {
      display: flex;
      flex-flow: row;

      .item {
        .item-action {
          bottom: 0 !important;
          right: 0 !important;
        }
      }
    }
  }

  .user-list {
    display: flex;
    flex-wrap: nowrap;
    padding: 0.9rem 0;

    .item {
      display: flex;
      flex-flow: column;
      background: #ffffff;
      margin: 0 0.5% 0.5% 0;
      min-width: 180px;
      width: 16.25%;
      padding: 0.5rem 0.4%;
      align-items: center;

      &:nth-child(6n) {
        margin-right: 0;
      }

      .avatar {
        width: 6rem;
        height: 6rem;
        border-radius: 6rem;
        object-fit: cover;
        background: #f5f5f5;
        color: #999999;
        text-align: center;
        line-height: 6rem;

        &:before {
          font-size: 3rem;
        }
      }

      .info {
        .name {
          font-size: 14px;
          color: #222222;
        }

        .country {
          width: 15px;
          object-fit: cover;
          margin: 0 0.5rem;
        }
      }

      .tags {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin: 0.5rem 0;
        height: 28px;
        overflow: hidden;

        li {
          padding: 0.1rem 0.5rem;
          border: 1px solid #33cc66;
          border-radius: 5rem;
          font-size: 12px;
          margin: 0.25rem;
          line-height: 18px;
          overflow: hidden;
          max-height: 24px;
        }
      }
    }
  }
}
</style>
