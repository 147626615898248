var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.services.length ||
    _vm.users.length ||
    _vm.groups.length ||
    _vm.companys.length
    ? _c(
        "div",
        { staticClass: "page search" },
        [
          _vm.services.length
            ? _c("div", { staticClass: "services" }, [
                _c("div", { staticClass: "category" }, [
                  _c("label", { staticClass: "title" }, [
                    _c("span"),
                    _vm.total.service > 8
                      ? _c("i", {
                          staticClass: "el-icon--homeMore",
                          on: {
                            click: function($event) {
                              return _vm.jumpPage({
                                name: "list",
                                params: {
                                  key: _vm.key,
                                  categoryId: _vm.categoryId
                                }
                              })
                            }
                          }
                        })
                      : _vm._e()
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "items" },
                  _vm._l(_vm.services, function(service, index) {
                    return _c("serviceItem", {
                      key: index,
                      attrs: {
                        data: _vm.formatServiceItem(service),
                        "light-height": true,
                        keyword: _vm.key
                      }
                    })
                  }),
                  1
                )
              ])
            : _vm._e(),
          _vm.users.length
            ? [
                _c("label", { staticClass: "title" }, [
                  _c("i", { staticClass: "type el-icon-user-solid" }),
                  _vm.total.user > 6
                    ? _c("i", {
                        staticClass: "more el-icon--homeMore",
                        on: {
                          click: function($event) {
                            return _vm.jumpPage({
                              name: "users",
                              params: {
                                key: _vm.key,
                                categoryId: _vm.categoryId,
                                type: 3
                              }
                            })
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _c("div", { staticClass: "box users" }, [
                  _c(
                    "div",
                    { staticClass: "user-list" },
                    _vm._l(_vm.users, function(user, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "item" },
                        [
                          _c("Avatar", {
                            attrs: {
                              src: _vm.formartImageUrl(user.profile_photo),
                              to: {
                                name: "personalCenter",
                                params: { id: user.provider_id }
                              }
                            }
                          }),
                          _c("p", { staticClass: "info" }, [
                            user.name
                              ? _c("span", {
                                  staticClass: "name",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.highlighted(
                                        user.name.slice(0, 20),
                                        _vm.key
                                      )
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.routeJump({
                                        name: "personalCenter",
                                        params: { id: user.provider_id }
                                      })
                                    }
                                  }
                                })
                              : _vm._e(),
                            user.country && user.country !== "0"
                              ? _c("img", {
                                  staticClass: "country",
                                  attrs: {
                                    src: _vm.getCountryIcon(user.country)
                                  }
                                })
                              : _vm._e()
                          ]),
                          _c(
                            "ul",
                            { staticClass: "tags" },
                            [
                              _vm._l(user.tags, function(tag, index1) {
                                return [
                                  _c("li", {
                                    key: index1,
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.highlighted(tag, _vm.key)
                                      )
                                    }
                                  })
                                ]
                              })
                            ],
                            2
                          ),
                          _c("actions", {
                            attrs: {
                              "action-data": user.actions,
                              "show-chat": true
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              ]
            : _vm._e(),
          _vm.groups.length
            ? [
                _c("label", { staticClass: "title" }, [
                  _c("i", { staticClass: "type el-icon--group" }),
                  _vm.total.group > 6
                    ? _c("i", {
                        staticClass: "more el-icon--homeMore",
                        on: {
                          click: function($event) {
                            return _vm.jumpPage({
                              name: "list-ges",
                              params: {
                                key: _vm.key,
                                categoryId: _vm.categoryId,
                                type: 4
                              }
                            })
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _c("div", { staticClass: "box" }, [
                  _c(
                    "div",
                    { staticClass: "item-list" },
                    [
                      _vm._l(_vm.groups, function(item, index) {
                        return [
                          _c("gigItem", {
                            key: index,
                            attrs: {
                              item: item,
                              small: true,
                              type: "4",
                              "light-height": true,
                              keyword: _vm.key
                            }
                          })
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]
            : _vm._e(),
          _vm.companys.length
            ? [
                _c("label", { staticClass: "title" }, [
                  _c("i", { staticClass: "type el-icon--company" }),
                  _vm.total.company > 6
                    ? _c("i", {
                        staticClass: "more el-icon--homeMore",
                        on: {
                          click: function($event) {
                            return _vm.jumpPage({
                              name: "list-ges",
                              params: {
                                key: _vm.key,
                                categoryId: _vm.categoryId,
                                type: 5
                              }
                            })
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _c("div", { staticClass: "box" }, [
                  _c(
                    "div",
                    { staticClass: "item-list" },
                    [
                      _vm._l(_vm.companys, function(item, index) {
                        return [
                          _c("gigItem", {
                            key: index,
                            attrs: {
                              item: item,
                              small: true,
                              type: "5",
                              "light-height": true,
                              keyword: _vm.key
                            }
                          })
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _c("noData")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }